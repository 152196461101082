import "core-js/es6/map"
import "core-js/es6/set"
import "raf/polyfill"
import React from "react"
import moment from "moment"

import { Layout, EventPageCarousel } from "../../containers"
import { SkewedHero, SkewedWrapper, Button, Modal } from "../../components"
import s from "./EventDetailBuilder.module.scss"
import {
  scrollToRef,
  getCurrentLanguage,
  getMetaDataOfEvent,
  formatEventDateToText,
} from "../../helper"
import { starts_in, SITE_SCOPES } from "../../constants"

import FacebookIcon from "../../assets/svgs/facebook-icon.svg"
import TwitterIcon from "../../assets/svgs/twitter-icon.svg"
import MailIcon from "../../assets/svgs/mail-icon.svg"
import ClockIcon from "../../assets/svgs/clock-icon.svg"
import MapPinHollow from "../../assets/svgs/map_pin_hollow.svg"
import ArrowDown from "../../assets/svgs/arrow_down.svg"

class EventDetailBuilder extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      eventData: this.formatEventData(),
      eventDoesNotExist: false,
      modalIsOpen: false,
      modalImageUrl: null,
      modalYoutubeLink: null,
      carouselData: null,
    }
    this.eventDateBlockRef = React.createRef()
    this.heroTitleRef = React.createRef()
    this.slickRef = React.createRef()
    this.contentRef = React.createRef()
  }
  formatEventData = () => {
    const { combined: currentLang, lang } = getCurrentLanguage(
      this.props.location
    )
    moment.locale(currentLang)
    const eventDetails = this.props.pageContext.childrenData
    const currentEvent = this.props.pageContext.rawEvents.find(
      (e) => e.title.rendered === this.props.pageContext.pageName
    )
    const metaData = getMetaDataOfEvent(currentEvent)

    const hero_block = eventDetails.find(
      (b) => b.type === "rivella/single-event-hero"
    )
    const details_block = eventDetails.find(
      (b) => b.type === "rivella/single-event-details"
    )
    const carousel_block = eventDetails.find(
      (b) => b.type === "rivella/single-event-carousel"
    )
    const { props: heroData } = hero_block
    const { props: detailsData } = details_block
    const { props: carouselData } = carousel_block
    const eventDate = moment(detailsData.date?.split("T")[0])
    const eventAlternateDate = detailsData.alternate_date
      ? moment(detailsData.alternate_date?.split("T")[0]).add(1, "day")
      : moment()
    const dateIsToBeDecided = detailsData.date_is_to_be_decided
    const eventDateText = formatEventDateToText({
      lang,
      dateIsToBeDecided,
      startDate: detailsData.date,
      endDate: detailsData.end_date,
      alternateStartDate: detailsData.alternate_date,
      alternateEndDate: detailsData.alternate_end_date,
      moment,
    })
    const pageType = metaData.type_of_page_key
      ? metaData.type_of_page_key
      : "event"

    let placeholdersFilteredSlides =
      carouselData && carouselData.slides
        ? carouselData.slides.filter(
            (s) => !s.url.includes("image-placeholder")
          )
        : []
    carouselData.slides = placeholdersFilteredSlides

    const testRegex = new RegExp("http.?://", "gm")
    let description = detailsData.description_one_text
    const http_exists_in_link = testRegex.test(description)
    if (http_exists_in_link) {
      description = description.replace(
        /<a href/gm,
        '<a rel="noopener noreferer" target="_blank" href'
      )
    } else {
      description = description.replace(
        /<a href="/gm,
        '<a rel="noopener noreferer" target="_blank" href="https://'
      )
    }
    return {
      description,
      eventDate,
      eventDateText,
      dateIsToBeDecided,
      eventAlternateDate,
      heroData,
      detailsData,
      carouselData,
      pageType,
    }
  }

  onCloseModal = () => {
    this.setState(
      {
        modalIsOpen: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            modalImageUrl: null,
            modalYoutubeLink: null,
          })
        }, 200)
      }
    )
  }

  onOpenModal = (link, type) => {
    if (!link || link === "#") return
    if (type === "youtube") {
      return this.setState({
        modalIsOpen: true,
        modalImageUrl: null,
        modalYoutubeLink: link,
      })
    } else if (type === "image") {
      return this.setState({
        modalIsOpen: true,
        modalImageUrl: link,
        modalYoutubeLink: null,
      })
    }
  }

  onArrowDown = () => {
    scrollToRef(this.contentRef, {
      behavior: "smooth",
      block: "center",
    })
  }

  animateSlideIns = () => {
    const { current: eventDateBlock } = this.eventDateBlockRef
    const { current: heroTitle } = this.heroTitleRef
    setTimeout(() => {
      if (eventDateBlock) eventDateBlock.classList.add(s.animated_title)
      if (heroTitle) heroTitle.classList.add(s.animated_title)
    }, 200)
  }

  componentDidMount() {
    this.animateSlideIns()
  }

  renderEventDoesNotExists = () => {
    return <div className={s.CenterSection}>This event does not exist</div>
  }

  onSlickNav = (to) => {
    if (
      !this.slickRef.current ||
      !this.state.eventData.carouselData ||
      !this.state.eventData.carouselData.slides
    )
      return
    const { modalYoutubeLink, modalImageUrl } = this.state
    const isVideoLink = !!modalYoutubeLink
    const currentLink = isVideoLink ? modalYoutubeLink : modalImageUrl
    const imageData = this.state.eventData.carouselData.slides
    const indexOf = imageData.findIndex((d) =>
      isVideoLink ? d.videoLink === currentLink : d.url === currentLink
    )
    const length = imageData.length
    if (to === "next") {
      this.slickRef.current.slickNext()
      const nextItem =
        length !== indexOf + 1 ? imageData[indexOf + 1] : imageData[0]
      if (nextItem.videoLink)
        return this.onOpenModal(nextItem.videoLink, "youtube")
      else return this.onOpenModal(nextItem.url, "image")
    } else if (to === "prev") {
      this.slickRef.current.slickPrev()
      const prevItem =
        indexOf !== 0 ? imageData[indexOf - 1] : imageData[length - 1]
      if (prevItem.videoLink)
        return this.onOpenModal(prevItem.videoLink, "youtube")
      else return this.onOpenModal(prevItem.url, "image")
    }
  }

  renderEvent = () => {
    const { combined: currentLang } = getCurrentLanguage(this.props.location)
    const { location } = this.props
    const { modalIsOpen, modalImageUrl, modalYoutubeLink, eventData } =
      this.state

    const {
      description,
      eventDate,
      eventAlternateDate,
      heroData,
      detailsData,
      carouselData,
      pageType,
      dateIsToBeDecided,
      eventDateText,
    } = eventData

    const placeholdersFilteredSlides = carouselData.slides
    return (
      <section className={s.event_page_wrapper}>
        <section
          className={s.hero_wrapper}
          itemScope
          itemType="https://schema.org/Event"
        >
          <SkewedHero
            cn={s.event_skewed_hero}
            imgLink={heroData.background_img}
            // imgFilename={extractImageNameFromUrl(heroData.background_img)}
            imgAlt={heroData.title_one_text}
            itemProp="image"
          />
          <ArrowDown className={s.ArrowDown} onClick={this.onArrowDown} />
          {heroData.title_at_bottom ? null : (
            <div
              ref={this.heroTitleRef}
              className={[s.hero_title, heroData.title_one_color].join(" ")}
              dangerouslySetInnerHTML={{
                __html: heroData.title_one_text,
              }}
              itemProp="name"
            />
          )}
          {pageType === "event" &&
          (!this.isPast(eventDate) || !this.isPast(eventAlternateDate)) &&
          !dateIsToBeDecided ? (
            <SkewedWrapper
              ref={this.eventDateBlockRef}
              type="primary"
              wrapperClassName={[s.hero_event_date, s.pulsated_block].join(" ")}
            >
              <p className={s.event_start_message}>{starts_in[currentLang]}:</p>
              <p
                className={s.event_start_date}
                itemProp="startDate"
                data-event={eventDate.toString()}
                data-alternate={eventAlternateDate.toString()}
                data-past={this.isPast(eventDate) ? "yes" : "no"}
                data-date={(!this.isPast(eventDate)
                  ? eventDate
                  : eventAlternateDate
                )?.toString()}
              >
                {moment(
                  !this.isPast(eventDate) ? eventDate : eventAlternateDate
                ).from(this.today(), true)}
              </p>
            </SkewedWrapper>
          ) : null}
        </section>
        {heroData.title_at_bottom ? (
          <div
            ref={this.heroTitleRef}
            className={[
              s.hero_title,
              heroData.title_one_color,
              s.hero_title__bottom,
            ].join(" ")}
            dangerouslySetInnerHTML={{
              __html: heroData.title_one_text,
            }}
            itemProp="name"
          />
        ) : null}
        <section className={s.social_links_section}>
          <div className={s.social_links}>
            <div className={s.icon_group}>
              {location && location.href && (
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=${location.href}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FacebookIcon />
                </a>
              )}
              {location && location.href && (
                <a
                  href={`https://twitter.com/intent/tweet?text=${
                    heroData.title_one_text
                  }&url=${
                    location.href
                  }&hashtags=Rivella,${heroData.title_one_text.replace(
                    /\s/g,
                    ""
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="twitter-share-button"
                >
                  <TwitterIcon />
                </a>
              )}
              {false && location && location.href && (
                <a
                  href={`mailto:info@example.com`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <MailIcon />
                </a>
              )}
            </div>
          </div>
        </section>
        <section
          className={s.event_details_wrapper}
          ref={this.contentRef}
          itemScope
          itemType="https://schema.org/Event"
        >
          {pageType === "event" ? (
            <>
              <p className={s.event_date}>
                <ClockIcon />
                {/* {moment(eventDate).format("dd, DD.MM.YYYY")}
                {eventAlternateDate
                  ? ` ${andWordTranslation[lang]} ${moment(
                      eventAlternateDate
                    ).format("dd, DD.MM.YYYY")}`
                  : null} */}
                {eventDateText}
              </p>
              <p className={s.event_location} itemProp="location">
                <MapPinHollow />
                {detailsData.location}
              </p>
            </>
          ) : null}
          <p
            className={s.event_description}
            itemProp="description"
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
          <SkewedWrapper wrapperClassName={s.event_button_group}>
            {detailsData.show_button_one && (
              <a
                href={
                  detailsData.button_one_link &&
                  detailsData.button_one_link.startsWith("http")
                    ? detailsData.button_one_link
                    : "https://" + detailsData.button_one_link
                }
                target="_blank"
                rel="noopener noreferrer"
                className={s.link_wrapper}
              >
                <Button btnClassName={s.event_btn} type="primary">
                  {detailsData.button_one_text}
                </Button>
              </a>
            )}
            {detailsData.show_button_two && (
              <a
                href={
                  detailsData.button_two_link &&
                  detailsData.button_two_link.startsWith("http")
                    ? detailsData.button_two_link
                    : "https://" + detailsData.button_two_link
                }
                target="_blank"
                rel="noopener noreferrer"
                className={s.link_wrapper}
              >
                <Button btnClassName={s.event_btn} type="default">
                  {detailsData.button_two_text}
                </Button>
              </a>
            )}
          </SkewedWrapper>
        </section>
        <Modal onClose={this.onCloseModal} visible={modalIsOpen}>
          {placeholdersFilteredSlides &&
          placeholdersFilteredSlides.length > 1 ? (
            <ArrowDown
              className={[s.ModalArrow, s.ModalArrowLeft].join(" ")}
              onClick={() => this.onSlickNav("prev")}
            />
          ) : null}
          {modalYoutubeLink ? (
            <iframe
              src={modalYoutubeLink}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
              title="video"
              width="100%"
              height="440px"
            />
          ) : null}
          {modalImageUrl ? (
            <img className={s.ModalImage} src={modalImageUrl} />
          ) : // <ImgFluid
          //   cn={s.ModalImage}
          //   fileName={extractImageNameFromUrl(modalImageUrl)}
          // />
          null}
          {placeholdersFilteredSlides &&
          placeholdersFilteredSlides.length > 1 ? (
            <ArrowDown
              className={[s.ModalArrow, s.ModalArrowRight].join(" ")}
              onClick={() => this.onSlickNav("next")}
            />
          ) : null}
        </Modal>
        {!carouselData.is_hidden &&
        placeholdersFilteredSlides &&
        placeholdersFilteredSlides.length ? (
          <section className={s.events_carousel}>
            <EventPageCarousel
              slickRef={this.slickRef}
              onOpenModal={this.onOpenModal}
              imageData={carouselData.slides}
              title_one_text={carouselData.title_one_text}
              title_two_text={carouselData.title_two_text}
            />
          </section>
        ) : null}
      </section>
    )
  }
  isPast = (d) => new Date(d).getTime() < new Date().getTime()
  today = () => {
    let d = new Date()
    d = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0)
    return moment(d)
  }
  render() {
    const { eventData, eventDoesNotExist } = this.state
    let content = this.renderEvent()
    if (eventDoesNotExist) content = this.renderEventDoesNotExists()

    const pageData = eventData?.heroData
    const pageTitle = pageData?.title_one_text?.toLowerCase().trim()
    let pageDescription = ""

    switch (pageTitle) {
      case "langenthaler stadtlauf":
        pageDescription = `Der Langenthaler Stadtlauf bietet eine grosse Auswahl an Kategorien und ermöglicht so allen, den richtigen Lauf auszuwählen.`
        break
      case "slowup zurichsee":
        pageDescription = `Besuche uns mit der ganzen Familie in den drei Rivella-Zonen und gewinne eine Saisonlieferung Rivella oder nimm an unserer Hauptverlosung teil!`
        break
      case "lausanne marathon":
        pageDescription = `Halte deine Topform bis spät in die Saison, indem du an diesem Herbstrennen teilnimmst.`
        break
      case "20km genève":
        pageDescription = `Mit Rivella 20 Kilometer durch die herrliche Landschaft rund um Genf, mit Blick auf den Jura und den Mont Blanc.`
        break
      default:
        break
    }

    return (
      <Layout
        siteScope={SITE_SCOPES.main}
        headerIsTransparent
        pageName={this.props.pageContext.pageName}
        location={this.props.location}
        languageOptions={eventData ? eventData.languageOptions : null}
        pageDescription={pageDescription}
      >
        {content}
      </Layout>
    )
  }
}

export default EventDetailBuilder
